"use strict";

export const ENV = {
    production: "production",
    staging: "staging",
    testing: "testing",
    demo: "demo",
    local: "local",
};

export enum COLLECTIONS {
    AGENCIES = "agencies",
    BUSINESSSECTORS = "business_sectors",
    CERTIFICATIONS = "certifications",
    COMPANIES = "companies",
    JOBS = "jobs",
    MISSIONS = "missions",
    USERS = "users",
    UNAVAILABILITIES = "unavailabilities",
    CANDIDATES = "candidates",
}

export enum DBState {
    deleted = "deleted",
    disabled = "disabled",
}

export enum ErrorType {
    CompanyNotFound = "Company.NotFound",
    BusinessSectorNotFound = "BusinessSector.NotFound",
    AgencyNotFound = "Agency.NotFound",
    UserNotFound = "User.NotFound",
    EmailAlreadyTaken = "Validation.EmailIsAlreadyUsed",
    DepartmentNotFound = "Department.NotFound",
    EvoliaConnexionFailed = "Evolia.Connexion.failed",
}

export enum MissionStatus {
    draft = "draft", // super status: newCommand
    created = "created", // super status: newCommand

    inPreparation = "inPreparation", // super status: inProgressCommand
    presented = "presented", // super status: inProgressCommand
    proposed = "proposed", // super status: inProgressCommand

    ready = "ready", // super status: futureMission
    completed = "completed", // super status: futureMission
    // à traduire par "clôturée"

    inProgress = "inProgress", // super status: inProgressMission

    evaluation = "evaluation", // super status: finishedMission
    terminated = "terminated", // super status: finishedMission

    archived = "archived",
    deleted = "deleted",
    canceled = "canceled",
    oldConfirmed = "confirmed",
    oldTodo = "todo",
    oldWaiting = "waiting",
}

export const MissionStatusGroup = {
    unclosedStatus: [
        MissionStatus.draft,
        MissionStatus.created,
        MissionStatus.inPreparation,
        MissionStatus.presented,
        MissionStatus.proposed,
        MissionStatus.ready,
    ],
    closedStatus: [MissionStatus.completed, MissionStatus.inProgress, MissionStatus.evaluation, MissionStatus.terminated, MissionStatus.archived],
};

export enum WorkerMissionStatus {
    placed = "placed",
    presented = "presented", // présenté à l'entreprise
    validated = "validated", // validé par l'entreprise
    proposed = "proposed", // proposé à l'interim
    accepted = "accepted", // accepté par l'intérim
    declined = "declined", // décliné par l'intérim
    confirmed = "confirmed", // confirmé par l'agence
    rejectedByAgency = "rejectedByAgency", // non confirmé par l'agence ou l'entreprise
    rejectedByCompany = "rejectedByCompany", // refusé par l'entreprise
    aborted = "aborted", // annulé
    notconfirmed = "notconfirmed",
    reproposed = "reproposed", // relancé
}

export enum MissionCommand {
    update = "update",
    copy = "copy",
    delete = "delete",
    cancel = "cancel",
    addInterim = "addInterim",
    propose = "propose",
    present = "present",
    confirm = "confirm",
    complete = "complete",
    extend = "extend",
    evaluate = "evaluate",
    archive = "archive",
    sendOrderToEvolia = "sendOrderToEvolia",

    askAgency = "askAgency",
    agencyDecline = "agencyDecline",

    workerPresent = "workerPresent",
    workerValidate = "workerValidate",
    workerPropose = "workerPropose",
    workerAccept = "workerAccept",
    workerDecline = "workerDecline",
    workerConfirm = "workerConfirm",
    workerAgencyReject = "workerAgencyReject",
    workerCompanyReject = "workerCompanyReject",
    workerAbort = "workerAbort",
}

export enum JobExperience {
    none = "none",
    beginner = "beginner",
    intermediate = "intermediate",
    advanced = "advanced",
    expert = "expert",
}

export const JobExperienceLevel = {
    [JobExperience.none]: 0,
    [JobExperience.beginner]: 25,
    [JobExperience.intermediate]: 50,
    [JobExperience.advanced]: 75,
    [JobExperience.expert]: 100,
};

export enum SubscriptionType {
    starter = "starter",
    intermediate = "intermediate",
    full = "full",
}

export enum CancelReason {
    clientNeedNoLongerExists = "clientNeedNoLongerExists",
    fasterCompetition = "fasterCompetition",
    noAvailableWorker = "noAvailableWorker",
    workerDidNotShowUp = "workerDidNotShowUp",
    customerChanges = "customerChanges",
    other = "other",
}

export enum NotificationType {
    InterimInvitation = "interim_invitation",
    SenderSentInterimInvitation = "sender_sent_interim_invitation",
    InterimInvitationReminder = "interim_invitation_reminder",
    SenderSentInterimInvitationReminder = "sender_sent_interim_invitation_reminder",
    ProposedMissionToWorker = "proposed_mission_to_worker",
    SenderProposeMissionToWorker = "sender_propose_mission_to_worker",
    ConfirmedMissionToWorker = "confirmed_mission_to_worker",
    SenderConfirmMissionToWorker = "sender_confirm_mission_to_worker",

    WorkerAccepted = "worker_accepted",
    WorkerDeclined = "worker_declined",

    CollaboratorInvitation = "collaborator_invitation",
    PresentWorkers = "present_workers",
    WorkersValidated = "worker_validated",
    WorkerRejectedByClient = "worker_rejected_by_client",
    WorkerRejected = "worker_rejected",
    MissionCanceled = "mission_canceled",
    MissionEvaluated = "mission_evaluated",
    MissionInProgress = "mission_inprogress",
    MissionTerminated = "mission_terminated",
    MissionTimeoutArchived = "mission_timeout_archived",
    MissionReady = "mission_ready",
    MissionAutoCompleted = "mission_auto_completed",
}

export enum NotificationLevel {
    info = "info",
    warning = "warning",
    alert = "alert",
}

export enum NotificationStatus {
    unread = "unread",
    read = "read",
    terminated = "terminated",
}

export enum NotificationMode {
    email = "email",
    sms = "sms",
    push = "push",
    database = "database",
}

export enum Gender {
    Male = "male",
    Female = "female",
}

export enum PreconfiguredViewSections {
    Monitoring = "monitoring",
    Schedule = "schedule",
    TemporaryWorkers = "temporaryworkers",
    Clients = "clients",
    Statistics = "statistics",
}

export enum UserStatus {
    Invited = "invited",
    Registered = "registered",
    Imported = "imported",
    Inactive = "inactive",
    NoLongerRegistered = "no-longer-registered",
}

export enum UserRole {
    Collaborator = "collaborator",
    Interim = "interim",
    Manager = "manager",
    Company = "company",
    Supervisor = "supervisor",
}

export enum JobService {
    talentplug = "talentplug",
    interactionweb = "interactionweb",
    merciii = "merciii",
    vigijobs = "vigijobs",
    indeed = "indeed",
    meteojob = "meteojob",
}
export enum EJobService {
    UNKNOW = "Unknow",
    LINKEDIN = "Linkedin",
    INDEED = "Indeed",
    HELLOWORK = "Hellowork",
    INTERACTIONWEB = "Interactionweb",
    MERCIII = "Merciii",
    VIGIJOBS = "Vigijobs",
    WIZIM = "Wizim",
    ADDMANUEL = "Ajout manuel",
    ESPACECARRIERE = "Espace-carriere",
    FRACETRAVAIL = "France-travail",
    POLEEMPLOI = "Pole-emploi",
    TALENTPLUG = "Talentplug",
}

export enum JobOfferStatus {
    draft = "draft",
    published = "published",
    unpublished = "unpublished",
}

export enum JobOfferCommand {
    post = "post",
    edit = "edit",
    cancel = "cancel",
    duplicate = "duplicate",
    unpost = "unpost",
    archive = "archive",
    refresh = "refresh",
    delete = "delete",
    republish = "republish",
}

export enum CandidateStatus {
    new = "new",
    rejected = "rejected",
    integrated = "integrated",
    interesting = "interesting",
    meeting = "meeting",
    met = "met",
}

export enum CandidateCommand {
    interested = "interested",
    reject = "reject",
    scheduleMeeting = "schedule-meeting",
    integrate = "integrate",
    update = "update",
    sendMessage = "send-message",
    meetingResult = "meeting-result",
    immediateMeeting = "immediate-meeting",
    archived = "archived",
    contact = "contact",
    noteAgency = "note-agency",
    voiceMessage = "voice-message",
    propose = "propose",
}

export enum CandidateOrigin {
    not_specified = "Sans origine",
    empty = "",
    ac = "Annonce Cookorico",
    aes = "Annonce Emploi Soignant",
    ah = "Annonce Hellowork",
    ai = "Annonce Indeed",
    aj = "Annonce Jobtransport",
    albc = "Annonce Le Bon Coin.fr",
    al = "Annonce Linkedin",
    amj = "Annonce Météo Job",
    aof = "Annonce Ouest France",
    aft = "Annonce France travail (pôle emploi)",
    asig = "Annonce Site Internet Groupe",
    ass = "Annonce Staffsanté",
    aaj = "Annonce autres Job Board",
    avj = "Annonce Vigijobs",
    bao = "Bouche à oreille",
    bace = "Bretagne Ateliers / Cap Emploi",
    bi = "Bus Interaction",
    cs = "Candidature spontanée",
    ca = "Centre d'appel",
    cc = "Cvthèque Cookorico",
    ces = "Cvthèque Emploi Soignant",
    chw = "Cvthèque Hellowork",
    cm = "Cvthèque Meteojob",
    cft = "Cvthèque France Travail (ex pôle emploi)",
    csig = "Cvthèque site internet Groupe",
    ef = "Ecoles/Formations",
    epc = "Envoyée par le client",
    fb = "Facebook",
    mi = "Merciii",
    p = "Parrainage",
    pfml = "Partenariats (France Travail, Mission locale...)",
    pa = "Passage Agence / Annonce Vitrine",
    rp = "Radio, Presse...",
    rc = "Reprise Celtic",
    rebc = "Reprise EBC",
    rg = "Reprise Gezim",
    meetup = "Salon / Forum",
}

export enum sortOptions {
    az = "Trier de A-Z",
    za = "Trier de Z-A",
    ascending = "Trier par ordre croissant",
    descending = "Trier par ordre décroissant",
}

export enum MeetingResult {
    integrate = "integrate",
    reject = "reject",
    save = "save",
}

export enum MessageTemplateType {
    ats_agency_message = "ats_agency_message",
    ats_agency_rejection = "ats_agency_rejection",
    ats_agency_interview = "ats_agency_interview",
}

export enum Feature {
    ats = "ats",
    ATS_JOB_OFFERS = "ats-job-offers",
    salesforce = "salesforce",
    EVOLIA_ORDERS = "evolia-orders",
}

export enum JobServiceStatus {
    unpublished = "unpublished",
    draft = "draft",
    published = "published",
    deleted = "deleted",
    archived = "archived",
}

export enum CandidateLogType {
    message = "message",
    candidateMessage = "candidate-message",
    interview = "interview",
    reject = "reject",
    status = "status",
    create = "create",
    createFromInterim = "create-from-interim",
    interesting = "interesting",
    feedBack = "feed-back",
    integration = "integration",
    update = "update",
    parsed = "parsed",
    analysisValidation = "analysis-validation",
    note = "note",
    contact = "contact",
    voiceMessage = "voice-message",
    phoneReport = "phone-report",
}

export enum TeamPreference {
    alone = "alone",
    team = "team",
    both = "both",
}

export enum ESortByDate {
    olderToRecent = "Plus ancien au plus récent",
    recentToOlder = "Plus récent au plus ancien",
}

export enum ESelectMultiple {
    NO_ONE = "Aucun",
    ALL = "Tous",
    TOP_TEN = "Les 10 premiers",
    TOP_TWENTY = "Les 20 premiers",
    TOP_FIFTY = "Les 50 premiers",
}

export const ROUTES = {
    LOGIN: "login",
    LOGAS: "logas",
    RESETPASSWORD: "reset-password",
    INVITATION: "invitation",
    PRESENTATION: "presentation",
    REGISTER: "register",
    REGISTER_AGENCY: "register-agency",
    REGISTER_COMPANY: "register-company",

    APP: {
        _ROOT: "app",
        HOME: "home",
        FOLLOWING: {
            _ROOT: "app.following._root",
            GLOBAL: "app.following.global",
            ARCHIVE: "app.following.archive",
        },
        PLANNING: {
            _ROOT: "app.schedule._root",
            ORDER: "app.schedule.order",
            WORKERS: "app.schedule.workers",
            COMPANIES: "app.schedule.companies",
        },
        INTERIMS: {
            _ROOT: "app.interims._root",
            LIST: "app.interims.list",
            MAP: "app.interims.map",
            INVITATIONS: "app.interims.invitations",
            AVAILABILITIES: "app.interims.availabilities",
            GESTION_INACTIFS: "app.interims.gestion-inactif",
        },
        INTERIM: {
            _ROOT: "app.interim._root",
        },
        WORKER: {
            INTERIM_DOCUMENTS: "app.worker.interim-documents",
            CANDIDATE_DOCUMENTS: "app.worker.candidate-documents",
            WORKER_DOCUMENTS: "app.worker.worker-documents",
            WORKER_JOBS: "app.worker.workerJobs",
            INTERIM_JOB: "app.worker.interimJobs",
            CANDIDATE: "app.worker.candidate",
            INTERIM_IDENTITY: "app.worker.interim-identity",
            CANDIDATE_IDENTITY: "app.worker.candidate-identity",
            INTERIM_FOLLOWING: "app.worker.interim-suivi",
            CANDIDATE_FOLLOWING: "app.worker.candidate-suivi",
            INTERIM_PREFERENCES: "app.worker.interim-preferences",
            CANDIDATE_PREFERENCES: "app.worker.candidate-preferences",
            INTERIM_STATISTICS: "app.worker.interim-statistics",
            INTERIM_JOBOFFERS: "app.worker.interim-joboffers",
            CANDIDATE_JOBOFFERS: "app.worker.candidate-joboffers",
            INTERIM_SCHEDULE: "app.worker.interim-schedule",
            INTERIM_REVIEWS: "app.worker.interim-reviews",
        },

        CANDIDATE: "candidate",
        INTERIMS_LIST: "Vivier",
        INTERIMS_INVITATIONS: "Invitations",
        INTERIMS_AVAILABILITIES: "Disponibilités",
        INTERIMS_GESTION_INACTIFS: "Inactifs identifiés",
        CLIENTS: "clients",
        CLIENT: {
            _ROOT: "app.client._root",
            GENERAL: "app.client.general",
            CONTACT: "app.client.contact",
            ORDER: "app.client.order",
            PROPOSITIONS: "app.client.propositions",
            REVIEWS: "app.client.reviews",
        },
        AGENCIES: "agencies",
        STATISTICS: "app.statistics",
        MISSION: {
            _ROOT: "mission",
            DETAIL: "mission-detail",
            PRESENTATION: "mission-presentation",
        },
        AGENCYPROFILE: "agencyProfile",
        COMPANYPROFILE: "companyProfile",
        PROFILE: "profile",
        MISSIONCREATION: "missionCreation",
        COLLABORATORS: "collaborators",
        NOTIFICATIONS: "notifications",
        ACTIVE_PROPOSITION: {
            _ROOT: "app.activeProposition._root",
            PROPOSITIONS: "app.activeProposition.propositions",
            COMPANIES: "app.activeProposition.companies",
        },

        ATS: {
            _ROOT: "app.ats._root",
            SUPERVISOR_STATISTICS: "app.ats.statistiques",
            FOLLOWING: {
                _ROOT: "app.ats.following._root",
                IDENTIFICATION: "app.ats.following.identification",
                KANBAN: "app.ats.following.kanban",
                LIST: "app.ats.following.list",
                PLANNING: "app.ats.following.planning",
            },
            CV: {
                _ROOT: "app.ats.cv._root",
                LIST: "app.ats.cv.list",
                NEWCANDIDAT: "app.ats.cv.newcandidat",
            },
            JOBOFFER: {
                _ROOT: "app.ats.joboffer._root",
                LIST: "app.ats.joboffer.list",
                ADD: "app.ats.joboffer.add",
                OLD: "app.ats.joboffer.archive",
                READ: {
                    _ROOT: "app.ats.joboffer.read._root",
                    KANBAN: "app.ats.joboffer.read.kanban",
                    LIST: "app.ats.joboffer.read.list",
                },
            },
            CANDIDATE: {
                _ROOT: "app.ats.candidate._root",
                PERSON: "app.ats.candidate.person",
                CV: "app.ats.candidate.cv",
                PARSING: "app.ats.candidate.parsing",
                FOLLOWING: "app.ats.candidate.following",
                EDITION: "app.ats.candidate.edition",
            },
        },
    },
};

export enum Brand {
    interaction = "interaction",
}

export enum ECandidateOfferStatus {
    APPLIED = "applied",
    TOSTUDY = "tostudy",
    PROPOSED = "proposed",
    RETAINED = "retained",
    NOTRETAINED = "notretained",
}

export enum EWorkerHabilitationType {
    BTP_CARD = "btp-card",
    HABILITATION = "habilitation",
    DEGREE = "degree",
    MEDICAL_CHECK_UP = "medical-check-up",
    OFFICIAL_DOCUMENTS = "official-documents",
}

export enum ESearchWorkerModes {
    INTERIM = "interim",
    CANDIDATE = "candidate",
    EVOLIA = "evolia",
}

export enum ELogType {
    // COMMONS LOG
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    NOTE = "note",
    ADD_CV = "add-cv",
    IMPORT_EVOLIA = "import-evolia",
    ADD_AGENCY = "add-agency",
    UPDATE_PROFILE = "update-profile",

    // INTERIM ACTIONS
    ACTIVE = "active",
    INACTIVE = "inactive",

    // CANDIDATE
    INTEGRATE = "integrate",
    CHANGE_STATUS = "change-status",
    INTERVIEW = "interview",
    CONTACT = "contact",
    FEEDBACK = "feedback",
    CREATE_FROM_INTERIM = "create-from-interim",
    INTEGRATE_FROM_INTERIM = "integrate-from-interim",
    NEW_APPLICATION = "new-application",

    // REJECT          =    'reject',
    // status             = 'status',
    // interesting        = 'interesting',
    // feedBack           = 'feed-back',
    // contact            = 'contact',
    // voiceMessage       = 'voice-message',

    // candidateMessage   = 'candidate-message',
    // analysisValidation = 'analysis-validation',
    // phoneReport        = 'phone-report'
}

export enum ECandidateContact {
    contact = "contact",
    voiceMessage = "voice-message",
    phoneReport = "phone-report",
}

export enum ECandidateRole {
    CANDIDATE = "candidate",
    TOIDENTIFICATE = "to-identificate",
}

export enum EWorkerDatabaseOrigin {
    INTERIM = "interim",
    CANDIDATE = "candidate",
    EVOLIA = "evolia",
}

export enum EWorkerCandidateStatus {
    ARCHIVED = "archived",
    REJECTED = "rejected",
    NEW = "new",
    INTERESTING = "interesting",
    MEETING = "meeting",
    MET = "met",
    INTEGRATED = "integrated",
    REGISTERED = "registered",
    REINVITED = "reinvited",
}
export enum EContractType {
    NONE = "none",
    INTERIM = "interim",
    SEASONAL = "seasonal",
    CDD = "cdd",
    CDI = "cdi",
    VACATION = "vacation",
}

export enum EContractTypeSearch {
    ALL = "all",
    NONE = "none",
    INTERIM = "interim",
    SEASONAL = "seasonal",
    CDD = "cdd",
    CDI = "cdi",
    VACATION = "vacation",
}

export enum CandidateActionsModalsNames {
    TO_MEET_MODAL = "ToMeetModal",
    DATE_SCHEDULING_MODAL = "DateSchedulingModal",
    AFTER_MEETING_MODAL = "AfterMeetingModal",
    INTEGRATION_MODAL = "IntegrationModal",
    REJECTION_MODAL = "RejectionModal",
    NOTE_AGENCY_MODAL = "NoteAgencyModal",
    CREATE_PROPOSAL_MODAL = "CreateProposalModal",
}

export enum PhoneOperatingSystems {
    IOS = "ios",
    Android = "android",
}

export enum EWorkerDocumentType {
    BTP_CARD = "btp-card",
    HABILITATION = "habilitation",
    DEGREE = "degree",
    MEDICAL_CHECK_UP = "medical-check-up",
    OFFICIAL_DOCUMENTS = "official-documents",
}

export const FEATURE_KEYS = {
    breadcrumb: "breadcrumb", // Fil d'arianne
    activeProposition: "activeProposition", // Proposition active
};
